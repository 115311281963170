.react-flow__edge,
.react-flow__edge-path {
  pointer-events: none;
  stroke-width: 2;
  stroke: rgb(255, 255, 255);
}

.react-flow__node {
  border: 0px solid rgb(255, 255, 255);
  background-color: rgb(85, 85, 85);
  color: white;
  cursor: default;
  width: 80px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  box-shadow: 8px 5px 2px 1px rgba(0, 0, 255, 0.2);
  cursor: pointer;
}

.react-flow__node.selected {
  background-color: white;
  border: 0px solid black;
  color: rgba(85, 85, 85, 1);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 255, 0);
}

.react-flow__node.loading {
  background: linear-gradient(
    to left,
    rgba(85, 85, 85, 1) 0px,
    rgba(85, 85, 85, 0.5) 50px
  );
  background-size: 100px 30px;
  animation: animate-stripes 3s linear infinite;
}

.react-flow__node.loading.selected {
  background: linear-gradient(
    to left,
    rgb(243, 243, 243) 0px,
    rgba(255, 255, 255, 0.5) 100px
  );
  color: rgba(85, 85, 85, 1);
  background-size: 100px 30px;
}

.react-flow__node.first {
  border-radius: 40px;
  padding: 20px;
  width: 120px;
  font-size: medium;
  opacity: 1;
}

.react-flow__node.first:hover {
  background-color: white;
  color: rgba(85, 85, 85, 1);
}

@keyframes animate-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100px 0;
  }
}

.react-flow__node:hover {
  background: rgb(255, 255, 255);
  color: rgb(49, 49, 49);
}

.react-flow__handle {
  opacity: 0;
  cursor: default !important;
}
